import script from "./GenerateExcelOfOrdersListModal.vue?vue&type=script&setup=true&lang=js"
export * from "./GenerateExcelOfOrdersListModal.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog,QCard,QForm,QCardActions});
